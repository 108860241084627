import React, { Component } from 'react';
import { Panel, ControlLabel, Glyphicon } from 'react-bootstrap';
import './Profile.css';

class Profile extends Component {
  componentWillMount() {
    this.setState({ profile: {} });
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
      });
    } else {
      this.setState({ profile: userProfile });
    }
  }
  render() {
    const { profile } = this.state;
    return (
      <div className="container">
        <div className="profile-area">
          <h1>Info from /userinfo</h1>
          <Panel header={profile.nickname}>
            <img src={profile.picture} alt="profile" />
            <div>
              <ControlLabel><Glyphicon glyph="user" /> Nickname</ControlLabel>
              <h3>{profile.nickname}</h3>
            </div>
            <pre>{JSON.stringify(profile, null, 2)}</pre>
          </Panel>
        </div>
        <h1>Info from id_token</h1>
        <Panel header="id_token">
          <pre>{JSON.stringify(require('jwt-decode')(this.props.auth.getIdToken()), null, 2)}</pre>
        </Panel>
        <h1>Info from access_token</h1>
        <Panel header="access_token">
          <pre>{JSON.stringify(require('jwt-decode')(this.props.auth.getAccessToken()), null, 2)}</pre>
        </Panel>
      </div>
    );
  }
}

export default Profile;
