import React, { Component } from 'react';
import { Button, Panel } from 'react-bootstrap';
import { API_URL } from './../constants';
import axios from 'axios';

class Api extends Component {
    componentWillMount() {
        this.setState({ message: '' });
    }
    getB() {
        const { getAccessToken } = this.props.auth;
        const headers = { 'Authorization': `Bearer ${getAccessToken()}` }
        axios.get(`${API_URL}/things/b`, { headers })
            .then(response => this.setState({ message: JSON.stringify(response.data, null, 2) }))
            .catch(error => this.setState({ message: JSON.stringify(error.response.data, null, 2) }));
    }
    getA() {
        const { getAccessToken } = this.props.auth;
        const headers = { 'Authorization': `Bearer ${getAccessToken()}` }
        axios.get(`${API_URL}/things/a`, { headers })
            .then(response => this.setState({ message: JSON.stringify(response.data, null, 2) }))
            .catch(error => this.setState({ message: JSON.stringify(error.response.data, null, 2) }));
    }

    render() {
        const { getAccessToken } = this.props.auth;
        const { message } = this.state;
        const { permissions } = require('jwt-decode')(getAccessToken());
        return (
            <div className="container">
                <h1>Make a Call to the Server</h1>
                <Button bsStyle="primary" onClick={this.getA.bind(this)}>GET /v1/things/a</Button>
                        <Button bsStyle="primary" onClick={this.getB.bind(this)}>
                            GET /v1/things/b
              </Button>
                <h3>Permissions: {JSON.stringify(permissions)}</h3>
                <Panel header="Response">
                    <pre>{message}</pre>
                </Panel>
            </div>
        );
    }
}

export default Api;